@import '../partials';

.teaser {

  &__boundary {

  }

  &__image {

  }

  &__label {
  }

  &__title {

  }

  &__description {

  }

  &__link {

  }
}